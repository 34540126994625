import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../components/Navbar"
  
import "../styles/fontawesome-free-5.15.4-web/css/all.css"

  
export default ()  => (
  <>
{/*<Navbar></Navbar>*/}

  
<Seo />


<div class="h-screen w-screen bg-black">
    <div class="mx-auto max-w-7xl pt-16 sm:pt-24">
        <div class="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div class="space-y-8">
                    <div class="space-y-4">
                        <div class="space-y-2">
                            <span
                                class="rounded-full uppercase bg-pink-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                                Exclusive Motoryacht Charter
                            </span>
                            <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                                <span class="sm:text-6xl">
                                </span> Charter the 
                                <span
                                    class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-blue-600"> CANDELA MARBELLA
                                </span>
                                <br></br>
                                Motoryacht.
                            </h1>
                        </div>

                        <p class="text-base text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        Welcome to the ultimate luxury motoryacht charter experience in Marbella, Spain! Discover the breathtaking beauty of the Costa del Sol, explore the stunning Mediterranean coastline, and indulge in a memorable adventure aboard our exquisite luxury Candela yacht. Whether you're seeking a romantic getaway, celebrating a special occasion, or simply looking to create unforgettable memories with family and friends, our motoryacht charters offer an unparalleled experience of opulence and relaxation.
                        </p>
                        <div class ="space-x-4">
                        <a href="/about"><button class="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
                        More about
                        </button></a>
      
                        <a href="https://candelamarbella.com/en/sail-away/"><button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                        Book now
                        </button></a>
                        </div>

                    </div>

                    <div class="border-t border-gray-700"></div>

                    <div class="flex space-x-4 items-center text-white">
                        <div class="flex items-center space-x-2">
                            <span class="flex-shrink-0 text-xs font-medium leading-5">4,3</span>
                        </div>
                        <div class="h-4 border-l border-gray-700"></div>
                        <div class="flex items-center">
                            <svg class="h-4 w-4 fill-current text-yellow-500" viewBox="0 0 24 24">
                                <path
                                    d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z">
                                </path>
                            </svg>
                            <svg class="h-4 w-4 fill-current text-yellow-500" viewBox="0 0 24 24">
                                <path
                                    d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z">
                                </path>
                            </svg>
                            <svg class="h-4 w-4 fill-current text-yellow-500" viewBox="0 0 24 24">
                                <path
                                    d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z">
                                </path>
                            </svg>
                            <svg class="h-4 w-4 fill-current text-yellow-500" viewBox="0 0 24 24">
                                <path
                                    d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z">
                                </path>
                            </svg>
                            <svg class="h-4 w-4 fill-current text-yellow-500" viewBox="0 0 24 24">
                                <path
                                    d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z">
                                </path>
                            </svg>
                        </div>
                        <div class="h-4 border-l border-gray-700"></div>
                        <a href="https://candelamarbella.com/en/legal-notice/" target="_blank">Legal Notice
                        </a>
                        <div class="h-4 border-l border-gray-700"></div>
                        <a href="https://candelamarbella.com/en/privacy-policy/" target="_blank">Privacy Policy
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex items-center w-full col-span-6">
                <iframe frameborder="0" allowfullscreen="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    width="100%" height="100%"
                    src="https://candelamarbella.com/wp-content/uploads/2023/05/Yacht.mp4"
                    id="widget2">
                </iframe>
            </div>
        </div>
    </div>
</div>
</>
)
